import React from "react";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Layout from "components/Layout/Layout";
import { ButtonMedium } from "components/Design/Button/ButtonMedium";
import PageContext from "contexts/PageContext";
import { generateSupportURL } from "utils/generateSupportURL";
import { AYANA_JAKARTA_ID, JAKARTA_LOCATION } from "store/constants/hotel";
import { transformObject } from "utils/transformv4";

const JakartaThankYou = ({ data }) => {
  const { t } = useTranslation("homepage");
  const {
    cms,
    allNavigationMenuJson: {
      nodes: [navigations],
    },
    allSocialMediaJson: {
      nodes: [socialMediaList],
    },
  } = data;

  const {
    hotelsByLocale: [hotel],
    supportDrawer: [supportDrawer],
    destinationImagesSections,
    lvxLogo: [lvxLogo],
  } = transformObject(cms);

  const supportDrawerData = {
    ...supportDrawer,
    hotel: hotel,
  };

  return (
    <PageContext.Provider
      value={{
        location: JAKARTA_LOCATION,
      }}>
      <Layout
        destination={t("Jakarta")}
        seoTitle={t("Ayana Support")}
        showBanner={true}
        destinationCards={destinationImagesSections[0].images}
        navigations={navigations}
        hotel={hotel}
        socialMedia={socialMediaList}
        socialMediaLabel={t("Connect with Jakarta")}
        supportDrawer={supportDrawerData}
        lvxLogo={hotel.id === AYANA_JAKARTA_ID && lvxLogo}>
        <div className="py-40 md:py-60 text-center">
          <h1 className="text-m-h2 md:text-h1 font-semibold text-gray-text pb-12 md:pb-16">
            {t("Thank You")}
          </h1>
          <p className="w-9/12 md:w-2/6 m-auto text-gray-text text-12-20 md:text-16-24 pb-16 md:pb-24">
            {t("processingRequest")}
          </p>
          <Link to={generateSupportURL("jakarta/ayana-midplaza-jakarta")}>
            <ButtonMedium type="primary" label={t("backToSupport")} />
          </Link>
        </div>
      </Layout>
    </PageContext.Provider>
  );
};

export const query = graphql`
  query JakartaThankYou($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNavigationMenuJson(
      filter: { hotel: { eq: "AYANA MIDPLAZA, JAKARTA" } }
    ) {
      ...LocalNavigation
    }
    allSocialMediaJson(filter: { hotel: { eq: "AYANA MIDPLAZA, JAKARTA" } }) {
      nodes {
        hotel
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }
    cms {
      supportDrawer: sections(filters: { id: { eq: 293 } }) {
        ...NavSections
      }
      hotelsByLocale(language: $language, where: { id: "7" }) {
        data {
          id
          attributes {
            name
            whatsapp_contact
            custom_whatsapp_message
            location
            offices(sort: "sequence") {
              ...CmsOffices_v4
            }
            ...CmsHotelLogo_v4
          }
        }
      }
      lvxLogo: images(filters: { name: { eq: "LVX_Preferred_Logo" } }) {
        data {
          attributes {
            desktop {
              ...CmsUploadFileEntityResponse
            }
            mobile {
              ...CmsUploadFileEntityResponse
            }
          }
        }
      }
      destinationImagesSections: sections(filters: { id: { eq: 292 } }) {
        ...NavSections
      }
    }
  }
`;
export default JakartaThankYou;
